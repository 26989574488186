<template>
  <button
    class="pick-button"
    :class="[isActive ? 'active' : 'normal']"
    @click="onClick"
  >
    <p>{{ value }}</p>
    <img :src="iconSRC" />
  </button>
</template>

<script>
export default {
  props: {
    name: String,
    value: String,
    iconSRC: String,
    isActive: Boolean,
  },

  methods: {
    onClick: function () {
      this.$emit("onClick", this.name);
    },
  },
};
</script>

<style lang="scss" scoped>

.pick-button {
  color: #515465;
  font-size: rem(14);
  width: rem(160);
  height: rem(32);
  background-color: white;
  border-radius: rem(4);
  box-sizing: border-box;
  padding-left: rem(16);
  padding-right: rem(13);
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: rem(11);
  }
}

.active {
  border: solid rem(1) #325FAC;
}

.normal {
  border: solid rem(1) #d9d9d9;
}
</style>