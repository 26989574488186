<template>
  <div class="borrow">
    <p class="borrow-title">设备借出</p>
    <div class="borrow-sn">
      <p>已选择：{{ deviceInfo.sn }}</p>
    </div>
    <user-search class="borrow-user-search" :keywords="keywords" @onInputInput="onInputInput"></user-search>
    <div v-show="selectedUserIndex != undefined" class="borrow-user-name">
      <p>{{ selectedUser.name }}</p>
      <button class="borrow-user-name-delete-button" :style="{ backgroundImage: `url(${require('@imgs/delete.png')})` }"
        @click="onDeleteClick"></button>
    </div>
    <div class="borrow-user-info">
      <p>性别：{{ selectedUser.gender }}</p>
      <p class="borrow-user-info-phone">手机号：{{ selectedUser.mobile }}</p>
    </div>
    <div class="borrow-monitor-duration">
      <p>监测时段：</p>
      <pick-button name="monitorDuration" :value="monitorDurationText" :iconSRC="clockSRC"
        :isActive="isShowMonitorDurationList" @onClick="onPickButtonClick"></pick-button>
    </div>
    <div class="borrow-borrow-duration">
      <p>借出时长：</p>
      <pick-button name="borrowDuration" :value="borrowDayText" :iconSRC="arrowDownSRC"
        :isActive="isShowBorrowDurationList" @onClick="onPickButtonClick"></pick-button>
    </div>
    <div class="borrow-button-container">
      <button class="borrow-button-container-cancel" @click="onCancelClick">
        取消
      </button>
      <button class="borrow-button-container-borrow" @click="onBorrowClick">
        借出
      </button>
    </div>
    <paging-pick-list ref="userList" class="user-list" :pageIndex="pageIndex" :pickArray="userList"
      :selectedIndex="selectedBorrowDurationIndex" :isDataLoadCompleted="isDataLoadCompleted"
      @onListItemClick="onPickUserClick" @onNextPage="onNextPage"></paging-pick-list>
    <pick-list class="borrow-duration-list" :isShow="isShowBorrowDurationList" :pickArray="borrowDurationArray"
      :selectedIndex="selectedBorrowDurationIndex" @onListItemClick="onChangeBorrowDurationClick"></pick-list>
    <time-picker class="monitor-duration-list" :isShow="isShowMonitorDurationList" :selectedStartHour="selectedStartHour"
      :selectedStartMinute="selectedStartMinute" :selectedEndHour="selectedEndHour" :selectedEndMinute="selectedEndMinute"
      @onChangeMonitorDuration="onChangeMonitorDurationClick"></time-picker>
  </div>
</template>

<script>
import PickButton from "@c/device-borrow/borrow/pick-button.vue";
import PickList from "@c/common/pick-list.vue";
import PagingPickList from "./paging-pick-list.vue";
import TimePicker from "@c/device-borrow/borrow/time-picker/time-picker.vue";
import UserSearch from "./user-search.vue";
import { DateTool } from "@js/date-tool.js";

export default {
  components: {
    PickButton,
    PickList,
    PagingPickList,
    TimePicker,
    UserSearch,
  },

  props: {
    deviceInfo: Object,
  },

  data: function () {
    return {
      clockSRC: require("@imgs/clock.png"),
      arrowDownSRC: require("@imgs/arrow-down.png"),
      keywords: "",
      pageIndex: 1,
      rawUserList: [],
      selectedUserIndex: undefined,
      isShowBorrowDurationList: false,
      selectedBorrowDurationIndex: 0,
      isShowMonitorDurationList: false,
      selectedStartHour: 21,
      selectedStartMinute: 0,
      selectedEndHour: 6,
      selectedEndMinute: 0,
      isRequestLocked: false, //避免上拉多次触发请求
      isDataLoadCompleted: false,
    };
  },

  computed: {
    userList: function () {
      const tempArray = [];
      for (const rawItem of this.rawUserList) {
        const sex = findKey(GENDER_CODE, `${rawItem.sex}`);
        const creatDate = rawItem.gmtCreate
        const item = {
          name: rawItem.trueName,
          gender: sex,
          date: "添加时间：" + creatDate,
        };
        tempArray.push(item);
      }
      return tempArray;
    },

    borrowDurationArray: function () {
      const tempArray = [];
      for (const rawItem of BORROW_DAY_ARRAY) {
        const item = rawItem + "天";
        tempArray.push(item);
      }
      return tempArray;
    },

    selectedUser: function () {
      if (this.selectedUserIndex == undefined) {
        return {
          uid: "",
          name: "",
          gender: "",
          mobile: "",
        };
      } else {
        const rawUser = this.rawUserList[this.selectedUserIndex];
        return {
          uid: rawUser.id,
          name: rawUser.trueName,
          gender: findKey(GENDER_CODE, `${rawUser.sex}`),
          mobile: rawUser.mobile,
        };
      }
    },

    borrowDayText: function () {
      return this.borrowDurationArray[this.selectedBorrowDurationIndex];
    },

    monitorDurationText: function () {
      return (
        getTimeValue(this.selectedStartHour) +
        ":" +
        getTimeValue(this.selectedStartMinute) +
        " -  " +
        getTimeValue(this.selectedEndHour) +
        ":" +
        getTimeValue(this.selectedEndMinute)
      );
    },
  },

  beforeDestroy: function () {
    this.$store.commit("removeDeviceInfo");
  },

  methods: {
    onInputInput: function (keywords) {
      this.isShowMonitorDurationList = false;
      this.isShowBorrowDurationList = false;
      this.keywords = keywords;
      this.pageIndex = 1;
      this.userListRequest();
    },

    onDeleteClick: function () {
      this.keywords = "";
      this.rawUserList = [];
      this.selectedUserIndex = undefined;
    },

    onNextPage: function () {
      this.userListRequest();
    },

    onPickButtonClick: function (name) {
      switch (name) {
        case "monitorDuration":
          this.isShowBorrowDurationList = false;
          this.isShowMonitorDurationList = !this.isShowMonitorDurationList;
          break;
        case "borrowDuration":
          this.isShowMonitorDurationList = false;
          this.isShowBorrowDurationList = !this.isShowBorrowDurationList;
          break;
        default:
          break;
      }
    },

    onPickUserClick: function (selectedIndex) {
      this.selectedUserIndex = selectedIndex;
    },

    onChangeBorrowDurationClick: function (selectedIndex) {
      this.isShowBorrowDurationList = false;
      this.selectedBorrowDurationIndex = selectedIndex;
    },

    onChangeMonitorDurationClick: function (monitorDuration) {
      this.selectedStartHour = monitorDuration.startHour;
      this.selectedStartMinute = monitorDuration.startMinute;
      this.selectedEndHour = monitorDuration.endHour;
      this.selectedEndMinute = monitorDuration.endMinute;
    },

    onCancelClick: function () {
      this.$emit("onBackClick");
    },

    onBorrowClick: function () {
      //判断用户
      if (this.selectedUserIndex == undefined) {
        this.$toast.showRed(MSG.userIsEmpty);
        return;
      }
      // 判断监测时段
      if (this.selectedStartHour == this.selectedEndHour) {
        if (this.selectedEndMinute > this.selectedStartMinute) {
          this.$toast.showRed(MSG.lessThan4);
        } else {
          this.$toast.showRed(MSG.moreThan12);
        }
        return;
      }
      const trueEndHour =
        this.selectedEndHour > this.selectedStartHour
          ? this.selectedEndHour
          : this.selectedEndHour + 24;
      const duration = trueEndHour - this.selectedStartHour;
      if (duration <= 3) {
        this.$toast.showRed(MSG.lessThan4);
        return;
      }
      if (duration >= 13) {
        this.$toast.showRed(MSG.moreThan12);
        return;
      }
      if (duration == 4 && this.selectedEndMinute < this.selectedStartMinute) {
        this.$toast.showRed(MSG.lessThan4);
        return;
      }
      if (duration == 12 && this.selectedEndMinute > this.selectedStartMinute) {
        this.$toast.showRed(MSG.moreThan12);
        return;
      }
      this.borrowRequest();
    },

    // request
    async userListRequest() {
      if (this.isRequestLocked) {
        return;
      }
      this.isRequestLocked = true;
      try {
        const params = {
          "keyWord": this.keywords,
          "hospitalId": this.deviceInfo.hospitalId,
          // "sn": this.deviceInfo.sn,
          "pageNum": this.pageIndex,
          "pageSize": 10
        }
        const response = await this.$api.getUserPageList(params)
        this.isRequestLocked = false;
        if (this.pageIndex === 1) {
          this.rawUserList = [];
        }
        console.log("rawUserList1",this.rawUserList);
        this.rawUserList = this.rawUserList.concat(response.list);
       
        this.isDataLoadCompleted = this.pageIndex == response.pages;
        if (!this.isDataLoadCompleted) {
          this.pageIndex += 1;
        }
        let $userList = this.$refs.userList;
        $userList.show();
      } catch (error) {
        this.isRequestLocked = false;
        this.$toast.showRed(error);
      }
    },

    async borrowRequest() {
      try {
        const params = {
          "sn": this.deviceInfo.sn,
          "hospitalId": this.deviceInfo.hospitalId,
          "userId": this.selectedUser.uid,
          "monitor": this.monitorDurationText,
          "frequency": BORROW_DAY_ARRAY[this.selectedBorrowDurationIndex]
        }
        await this.$api.deviceBorrow(params)
        this.$emit("onBorrowSuccess");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.borrow {
  color: #4d4f5c;
  background-color: white;
  font-size: rem(14);
  line-height: rem(20);
  width: 100%;
  height: 100%;
  position: relative;

  &-title {
    color: #0D3F77;
    font-size: rem(28);
    line-height: rem(40);
    margin-bottom: rem(4);
  }

  &-sn {
    margin-top: rem(15);
  }

  &-user-search {
    width: 100%;
    height: rem(39);
    margin-top: rem(21);
  }

  &-user-name {
    background-color: white;
    border: 1px solid #dcdfe6;
    border-radius: rem(4);
    width: 100%;
    height: rem(43);
    position: absolute;
    top: rem(92);
    box-sizing: border-box;
    padding-left: rem(15);
    padding-right: rem(20);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-delete-button {
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
      width: rem(16);
      height: rem(16);
    }
  }

  &-user-info {
    width: 100%;
    margin-top: rem(14);
    display: flex;
    justify-content: space-between;

    &-phone {
      width: rem(150);
    }
  }

  &-monitor-duration {
    width: 100%;
    height: rem(32);
    margin-top: rem(21);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-borrow-duration {
    width: 100%;
    height: rem(32);
    margin-top: rem(16);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-button-container {
    width: 100%;
    height: rem(40);
    margin-top: rem(27);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-cancel {
      color: #325FAC;
      background-color: white;
      font-size: rem(16);
      border: solid 1px #325FAC;
      border-radius: rem(4);
      width: rem(100);
      height: rem(40);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-borrow {
      color: white;
      background-color: #325FAC;
      font-size: rem(16);
      border: solid 1px #325FAC;
      border-radius: rem(4);
      width: rem(100);
      height: rem(40);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .user-list {
    position: absolute;
    top: rem(135);
    width: rem(300);
  }

  .borrow-duration-list {
    position: absolute;
    top: rem(271);
    width: rem(160);
    left: rem(140);
  }

  .monitor-duration-list {
    position: absolute;
    top: rem(223);
    left: rem(140);
  }
}
</style>