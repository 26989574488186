<template>
  <div class="pick-list z-index-third" v-show="isShow">
    <ul class="pick-list-container" ref="ul" @scroll="onScroll">
      <button
        class="pick-list-container-item"
        :class="[
          index === selectedIndex
            ? 'pick-list-container-item-selected'
            : 'pick-list-container-item-normal',
        ]"
        v-for="(item, index) of pickArray"
        :key="index"
        @click="onListItemClick(index)"
      >
        <p>{{ item.name }} </p>
        <p>{{ item.gender }}</p>
        <p>{{ item.date.slice(0,15) }}</p>
      </button>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    pickArray: Array,
    selectedIndex: Number,
    pageIndex: Number,
    isDataLoadCompleted: Boolean,
  },

  data: function () {
    return {
      isShow: false,
    };
  },

  methods: {
    show: function () {
      this.isShow = true;
    },

    hide: function () {
      this.isShow = false;
    },

    onListItemClick: function (index) {
      this.$emit("onListItemClick", index);
      this.hide();
    },

    onScroll: function (e) {
      if (
        this.$refs.ul.scrollTop + 1 >=
        this.$refs.ul.scrollHeight - this.$refs.ul.clientHeight
      ) {
        if (!this.isDataLoadCompleted) {
          this.$emit("onNextPage");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.pick-list {
  background-color: white;
  width: 100%;

  &-container {
    width: 100%;
    max-height: rem(256);
    overflow-y: scroll;
    box-shadow: rem(0) rem(3) rem(8) rem(0) rgba($color: black, $alpha: 0.15);
    border-radius: rem(4);

    &-item {
      color: rgba($color: black, $alpha: 0.65);
      font-size: rem(14);
      line-height: rem(22);
      padding: rem(5) rem(10);
      border-bottom: 1px solid rgba($color: black, $alpha: 0.09);
      width: 100%;
      box-sizing: border-box;
      padding-left: rem(13);
      padding-right: rem(13);
      display: flex;
      justify-content: space-between;
      align-items: center;

      :last-child {
        border-bottom: none;
        border-bottom-left-radius: rem(4);
        border-bottom-right-radius: rem(4);
      }

      &:hover {
        background-color: #f7f7f7;
      }

      &-selected {
        background-color: #e6f7ff;

        &:hover {
          background-color: #e6f7ff;
        }
      }

      &-normal {
        background-color: white;
      }
    }
  }
}
</style>