<template>
  <div class="enter">
    <p class="enter-title">设备借还</p>
    <p class="enter-info">请选择一台设备，以便进行下一步</p>
    <device-search class="enter-search" @onInputInput="onInputInput"></device-search>
    <pick-list class="enter-list" :pickArray="deviceNameArray" :isShow="isShowList" :selectedIndex="selectedIndex"
      @onListItemClick="onPickListItemClick"></pick-list>
  </div>
</template>

<script>
import DeviceSearch from "@c/device-borrow/enter/device-search.vue";
import PickList from "@c/common/pick-list.vue";
export default {
  components: {
    DeviceSearch,
    PickList,
  },

  data: function () {
    return {
      deviceArray: [],
      selectedIndex: null,
      isShowList: false,
    };
  },

  computed: {
    hospitalID: function () {
      return this.$store.state.selectedHospital.hospitalid;
    },

    deviceNameArray: function () {
      const tempArray = [];
      for (let rawItem of this.deviceArray) {
        const item = `${rawItem.sn} ${rawItem.userName ?? ""} ${rawItem.userStatusDesc}`
        tempArray.push(item);
      }
      return tempArray;
    }
  },

  watch: {
    hospitalID: function () {
      this.deviceArray = []
      this.isShowList = false
      this.currentPage = 1;
      this.deviceListRequest();
    },
  },

  methods: {
    onInputInput: function (value) {
      this.deviceListRequest(value);
      this.isShowList = false;
    },

    onPickListItemClick: function (selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.deviceInfoRequest();
      this.isShowList = false;
    },

    async deviceListRequest(value) {
      try {
        const params = {
          hospitalId: this.hospitalID,
          pageNum: 1,
          pageSize: 1000,
          sn: value, //模糊查询
        }
        const response = await this.$api.getDeviceList(params)
        this.deviceArray = response.list
        this.isShowList = this.deviceArray.length != 0
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async deviceInfoRequest() {
      try {
        const params = {
          id: this.deviceArray[this.selectedIndex].id,
        }
        const response = await this.$api.getDeviceInfo(params)
        this.$emit("onPickListItemClick", response);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.enter {
  width: 100%;
  height: 100%;

  &-title {
    color: #262626;
    font-size: rem(28);
    line-height: rem(37);
  }

  &-info {
    color: #666666;
    font-size: rem(13);
    line-height: rem(17);
    margin-top: rem(16);
  }

  &-search {
    width: rem(250);
    height: rem(40);
    margin-top: rem(22);
  }

  &-list {
    width: rem(250);
  }
}
</style>