<template>
  <div class="success">
    <img class="success-icon" :src="successIcon" />
    <p class="success-title">{{ title }}</p>
    <div class="success-middle">
      <p>{{ info1 }}</p>
      <p>{{ info2 }}</p>
    </div>
    <div class="success-bottom">
      <button class="success-bottom-back" @click="onBackClick">返回</button>
      <button class="success-bottom-edit" v-if="isShowEdit" @click="onEditClick">
        编辑设备
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    successTypeCode: Number, //可以是borrow和recycle
  },

  data() {
    return {
      successIcon: require("@imgs/success.png"),
    };
  },

  computed: {
    title: function () {
      return findKey(DEVICE_HANDLE_TYPE_CODE, this.successTypeCode) + "成功";
    },
    info1: function () {
      return this.successTypeCode === DEVICE_HANDLE_TYPE_CODE.借出
        ? "请检查智能指环电量，"
        : "请将设备进行维保，";
    },
    info2: function () {
      return this.successTypeCode === DEVICE_HANDLE_TYPE_CODE.借出
        ? "并告知用户注意事项，指导用户如何进行监测！"
        : "设备回收成功！";
    },
    isShowEdit: function () {
      return this.successTypeCode === DEVICE_HANDLE_TYPE_CODE.回收;
    },
  },

  methods: {
    onBackClick: function () {
      this.$emit("onBackClick");
    },

    onEditClick: function () {
      this.$emit("onEditClick");
    },
  },
};
</script>

<style lang="scss" scoped>

.success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-icon {
    width: rem(72);
    height: rem(72);
    margin-top: rem(82);
  }

  &-title {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(24);
    line-height: rem(32);
    margin-top: rem(25);
  }

  &-middle {
    color: rgba($color: black, $alpha: 0.45);
    font-size: rem(14);
    line-height: rem(22);
    height: rem(47);
    margin-top: rem(14);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &-bottom {
    margin-top: rem(20);
    display: flex;
    align-items: center;

    &-back {
      color: white;
      background-color: #325FAC;
      border: rem(1) solid #325FAC;
      font-size: rem(14);
      width: rem(88);
      height: rem(32);
      border-radius: rem(4);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-edit {
      color: #5a5a5a;
      background-color: white;
      border: rem(1) solid #d9d9d9;
      font-size: rem(14);
      width: rem(88);
      height: rem(32);
      border-radius: rem(4);
      margin-left: rem(20);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>