<template>
  <div class="device-search" :class="{ active: isInputActive }">
    <input
      class="device-search-input"
      type="text"
      ref="input"
      v-model="value"
      @blur="onInputBlur"
      @click="onInputClick"
      @input="onInputInput"
    />
    <button
      class="device-search-icon"
      :style="{ backgroundImage: `url(${require('@imgs/search.png')})` }"
      @click="onIconClick"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    iconSrc: String,
  },

  data: function () {
    return {
      value: "",
      isInputActive: false,
    };
  },

  methods: {
    onInputBlur: function () {
      this.isInputActive = false;
      this.$emit("onInputBlur", this.value);
    },

    onInputClick: function () {
      this.isInputActive = true;
      this.$emit("onInputInput", this.value);
    },

    onInputInput: function () {
      this.isInputActive = true;
      this.$emit("onInputInput", this.value);
    },

    onIconClick: function () {
      let $input = this.$refs.input;
      $input.focus();
      this.isInputActive = true;
      this.$emit("onInputInput", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>


.device-search {
  background-color: white;
  border: 1px solid #dcdfe6;
  border-radius: rem(4);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: rem(15);
  padding-right: rem(12);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-input {
    color: black;
    opacity: 0.65;
    font-size: rem(14);
    border: none; // 去除未选中状态边框
    outline: none; // 去除选中状态边框
    flex: 1;
  }

  &-input::-webkit-input-placeholder {
    color: #909399;
    font-size: rem(14);
  }

  &-icon {
    background-size: rem(17) rem(18);
    background-repeat: no-repeat;
    width: rem(17);
    height: rem(18);
  }
}

.active {
  border: 1px solid #325FAC;
}
</style>