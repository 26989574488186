<template>
  <div class="device-borrow">
    <div class="device-borrow-content">
      <div class="device-borrow-content-top">
        <p class="device-borrow-content-top-title">设备借还</p>
      </div>
      <div class="device-borrow-content-bottom">
        <component :is="currentComponent" @onPickListItemClick="onEnterPickListItemClick"
          @onBackClick="onSuccessBackClick" @onEditClick="onRecycleSuccessEditClick" @onRecycleSuccess="onRecycleSuccess"
          @onBorrowSuccess="onBorrowSuccess" :successTypeCode="successTypeCode" :deviceInfo="selectedDeviceInfo">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import Success from "@c/device-borrow/success.vue";
import Enter from "@c/device-borrow/enter/enter.vue";
import Recycle from "@c/device-borrow/recycle.vue";
import Borrow from "@c/device-borrow/borrow/borrow.vue";
export default {
  components: {
    Success,
    Enter,
    Recycle,
    Borrow,
  },

  data: function () {
    return {
      currentComponent: undefined,
      selectedDeviceInfo: {},
      successTypeCode: DEVICE_HANDLE_TYPE_CODE.回收,
    };
  },

  created: function () {
    const deviceInfo = this.$store.state.deviceInfo;
    if (deviceInfo == undefined) {
      this.currentComponent = "enter";
    } else {
      if (deviceInfo.handleType == DEVICE_HANDLE_TYPE_CODE.借出) {
        this.currentComponent = "borrow";
        this.selectedDeviceInfo = deviceInfo.deviceModel;
      } else {
        this.currentComponent = "recycle";
        this.selectedDeviceInfo = deviceInfo.deviceModel;
      }
    }
  },

  methods: {
    onSuccessBackClick: function () {
      this.currentComponent = "enter";
    },

    onEnterPickListItemClick: function (deviceInfo) {
      this.selectedDeviceInfo = deviceInfo;
      switch (this.selectedDeviceInfo.type) {
        case DEVICE_BINDING_STATUS_CODE.闲置:
          this.currentComponent = "borrow";
          break;
        case DEVICE_BINDING_STATUS_CODE.维修:
          this.$toast.showRed("设备维修中,请稍后再试")
          break
        case DEVICE_BINDING_STATUS_CODE.维保:
          this.$toast.showRed("设备维保中,请稍后再试")
          break;
        case DEVICE_BINDING_STATUS_CODE.已借出:
          this.$toast.showRed("设备使用中")
          break;
        case DEVICE_BINDING_STATUS_CODE.待回收: //包括了逾期和待回收
          this.currentComponent = "recycle";
          break;
        default:
          break;
      }
    },

    onBorrowSuccess: function () {
      this.currentComponent = "success";
      this.successTypeCode = DEVICE_HANDLE_TYPE_CODE.借出;
    },

    onRecycleSuccess: function () {
      this.currentComponent = "success";
      this.successTypeCode = DEVICE_HANDLE_TYPE_CODE.回收;
    },

    onRecycleSuccessEditClick: function () {
      this.$emit("onRecycleSuccessEditClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.device-borrow {
  background-color: #eff2f7;
  width: 100%;
  height: 100%;

  &-content {
    background-color: white;
    border-radius: rem(2);
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    &-top {
      width: 100%;
      height: rem(72);

      &-title {
        color: #325FAC;
        font-size: rem(14);
        border-bottom: rem(2) solid #325FAC;
        width: rem(100);
        height: rem(32);
        margin-top: rem(25);
        margin-left: rem(36);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-bottom {
      width: rem(300);
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>