<template>
  <div class="recycle">
    <p class="recycle-title">设备回收</p>
    <div class="recycle-cell">
      <p>已选择：{{ deviceInfo.sn }}</p>
    </div>
    <div class="recycle-cell">
      <p>绑定用户：</p>
      <p>{{ deviceInfo.trueName }} {{ gender }}</p>
    </div>
    <div class="recycle-cell">
      <p>手机号：</p>
      <p>{{ deviceInfo.mobile }}</p>
    </div>
    <!-- <div class="recycle-cell">
      <p>检测设备：</p>
      <div class="recycle-cell-right">
    
        <button class="recycle-cell-right-button left" :class="[isDeviceDamaged ? 'gray' : 'blue']" @click="onGoodClick">
          完好
        </button>
    
        <button class="recycle-cell-right-button right" :class="[isDeviceDamaged ? 'blue' : 'gray']" @click="onBadClick">
          损坏
        </button>
      </div>
    </div> -->
    <div class="recycle-cell">
      <p>睡眠报告：</p>
      <p>{{ reportArray.length }}份</p>
    </div>
    <div class="recycle-report-list">
      <button class="recycle-report-list-item" :class="[item.canOpen ? 'green' : 'red']"
        v-for="(item, index) in reportArray" :key="index" @click="onReportClick(item)">
        <p>{{ item.name }}</p>
        <p>{{ item.quality }}</p>
      </button>
    </div>
    <div class="recycle-button-container">
      <button class="recycle-button-container-cancel" @click="onCancelClick">
        取消
      </button>
      <button class="recycle-button-container-recycle" @click="onRecycleClick">
        回收
      </button>
    </div>
    <div v-show="hasUnReceivedReport" class="recycle-bottom">
      <p>有未收取的血氧数据！</p>
      <p>请将戒指连接充电器并接通监护仪电源</p>
    </div>
  </div>
</template>

<script>
import { ReportPreviewTool } from "@js/report-preview-tool.js";

export default {
  props: {
    deviceInfo: Object,
  },

  data: function () {
    return {
      isDeviceDamaged: false,
    };
  },

  computed: {
    gender() {
      return findKey(GENDER_CODE, `${this.deviceInfo.sex}`)
    },

    reportArray: function () {
      const tempArray = [];
      const reportList = this.deviceInfo.reportList
      console.log("reportArray",reportList);
      if (reportList) {
        for (const rawItem of reportList) {
          const item = {
            name: rawItem.reportInfo,
            quality: findKey(REPORT_QUALITY_CODE, rawItem.quality),
            canOpen: findKey(REPORT_QUALITY_CODE, rawItem.quality) === "正常",
            reportURL: rawItem.reportNumber,
            createTime: rawItem.createDat,
            oxygenDeficiencyView: rawItem.oxygenDeficiencyView,
          };
          tempArray.push(item);
        }
      }
      return tempArray;
    },

    hasUnReceivedReport() {
      return (
        this.deviceInfo.reportStatus ==
        REPORT_STATUS_CODE.当前设备存在未收取的睡眠初筛报告
      );
    },
  },

  beforeDestroy: function () {
    this.$store.commit("removeDeviceInfo");
  },

  methods: {
    // onGoodClick: function () {
    //   this.isDeviceDamaged = false;
    // },

    // onBadClick: function () {
    //   this.isDeviceDamaged = true;
    // },

    onReportClick: function (report) {
      console.log("onReportClick",report);
      if (report.oxygenDeficiencyView == "1") {
        if (report.quality === "正常" || report.quality === "缺少血氧") {
          ReportPreviewTool.previewReport(
            this.deviceInfo.truename,
            report.reportURL,
            report.createTime
          );
        }
      } else {
        if (report.canOpen) {
          ReportPreviewTool.previewReport(
            this.deviceInfo.truename,
            report.reportURL,
            report.createTime
          );
        }
      }

    },

    onCancelClick: function () {
      this.$emit("onBackClick");
    },

    onRecycleClick: function () {
      this.recycleRequest();
    },

    async recycleRequest() {
      try {
        const params = {
          sn: this.deviceInfo.sn,
          // detectionEquipmentStatus: this.isDeviceDamaged
          //   ? DEVICE_RECYCLE_STATUS_CODE.损坏
          //   : DEVICE_RECYCLE_STATUS_CODE.完好,
        }
        await this.$api.deviceRecycle(params)
        this.$emit("onRecycleSuccess");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.recycle {
  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: rem(20);

  &-title {
    color: #0D3F77;
    font-size: rem(28);
    line-height: rem(40);
    margin-bottom: rem(4);
  }

  &-cell {
    color: #4d4f5c;
    font-size: rem(14);
    line-height: rem(20);
    width: 100%;
    height: rem(41);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-right {
      width: rem(119);
      height: rem(32);
      display: flex;

      &-button {
        width: rem(60);
        height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left {
        border-top-left-radius: rem(4);
        border-bottom-left-radius: rem(4);
      }

      .right {
        border-top-right-radius: rem(4);
        border-bottom-right-radius: rem(4);
      }
    }
  }

  &-report-list {
    width: 100%;
    margin-top: rem(-11);

    &-item {
      font-size: rem(14);
      width: 100%;
      height: rem(41);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &-button-container {
    width: 100%;
    height: rem(41);
    margin-top: rem(29);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-cancel {
      color: #325FAC;
      background-color: white;
      font-size: rem(16);
      border: solid 1px #325FAC;
      border-radius: rem(4);
      width: rem(100);
      height: rem(40);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-recycle {
      color: white;
      background-color: #325FAC;
      font-size: rem(16);
      border: solid 1px #325FAC;
      border-radius: rem(4);
      width: rem(100);
      height: rem(40);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-bottom {
    color: #909399;
    font-size: rem(12);
    width: 100%;
    height: rem(39);
    margin-top: rem(24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .blue {
    color: #325FAC;
    border: rem(1) solid #325FAC;
  }

  .gray {
    color: rgba($color: black, $alpha: 0.65);
    border: rem(1) solid #e8e8e8;
  }

  .bottom {
    margin-top: rem(29);
    display: flex;
  }

  .green {
    color: #6cc291;
    text-decoration: underline;
  }

  .red {
    color: #f45c50;
    text-decoration: none;
  }
}
</style>