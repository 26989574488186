<template>
  <div class="user-search" :class="{ active: isInputActive }">
    <input
      class="user-search-input"
      type="text"
      ref="input"
      v-model="value"
      @blur="onInputBlur"
      @click="onInputClick"
    />
    <button class="user-search-search" @click="onIconClick">搜索</button>
  </div>
</template>

<script>
export default {
  props: {
    keywords: String,
  },

  data: function () {
    return {
      value: "",
      isInputActive: false,
    };
  },

  watch: {
    keywords: function (newValue) {
      this.value = newValue;
    },
  },

  methods: {
    onInputBlur: function () {
      this.isInputActive = false;
    },

    onInputClick: function () {
      this.isInputActive = true;
    },

    onIconClick: function () {
      this.isInputActive = true;
      let $input = this.$refs.input;
      $input.focus();
      this.$emit("onInputInput", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>


.user-search {
  background-color: white;
  border: 1px solid #dcdfe6;
  border-radius: rem(4);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: rem(15);
  padding-right: rem(5);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-input {
    color: black;
    opacity: 0.65;
    font-size: rem(14);
    border: none; // 去除未选中状态边框
    outline: none; // 去除选中状态边框
    flex: 1;
  }

  &-input::-webkit-input-placeholder {
    color: #909399;
    font-size: rem(14);
  }

  &-search {
    color: white;
    background-color: #325FAC;
    font-size: rem(14);
    border-radius: rem(4);
    width: rem(48);
    height: rem(30);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.active {
  border: 1px solid #325FAC;
}
</style>