<template>
  <div class="column" ref="column">
    <button
      class="column-item"
      :class="{
        'column-item-selected': selectedItem === item,
      }"
      v-for="(item, index) of dataArray"
      :key="index"
      @click="onItemClick(item)"
    >
      {{ item | timeValue }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    dataArray: Array,
    selectedItem: Number,
  },

  methods: {
    onItemClick: function (item) {
      let $column = this.$refs.column;
      $column.scrollTop = 0;
      this.$emit("onItemClick", item);
    },
  },
};
</script>

<style lang="scss" scoped>


.column {
  border-right: rem(1) solid rgba($color: black, $alpha: 0.09);
  width: 25%;
  max-height: rem(129);
  overflow: hidden;
  overflow-y: auto;

  &-item {
    width: 100%;
    height: rem(32);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f7f7f7;
    }

    &-selected {
      background-color: #e6f7ff;

      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}
</style>