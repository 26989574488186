<template>
  <transition name="fold">
    <div class="time-picker" v-if="isShow">
      <div class="time-picker-top">
        <p>开始时间</p>
        <p>～</p>
        <p>结束时间</p>
      </div>
      <div class="time-picker-bottom">
        <column
          :dataArray="startHourArray"
          :selectedItem="selectedStartHour"
          @onItemClick="onStartHourClick"
        ></column>
        <column
          :dataArray="startMinuteArray"
          :selectedItem="selectedStartMinute"
          @onItemClick="onStartMinuteClick"
        ></column>
        <column
          :dataArray="endHourArray"
          :selectedItem="selectedEndHour"
          @onItemClick="onEndHourClick"
        ></column>
        <column
          :dataArray="endMinuteArray"
          :selectedItem="selectedEndMinute"
          @onItemClick="onEndMinuteClick"
        ></column>
      </div>
    </div>
  </transition>
</template>

<script>
import Column from "./column.vue";
export default {
  components: {
    Column,
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },

    selectedIndex: {
      type: Number,
      default: 0,
    },

    selectedStartHour: Number,
    selectedStartMinute: Number,
    selectedEndHour: Number,
    selectedEndMinute: Number,
  },

  computed: {
    startHourArray: function () {
      return this.composeDataArray(MONITOR_HOUR_ARRAY, this.selectedStartHour);
    },

    startMinuteArray: function () {
      return this.composeDataArray(
        MONITOR_MINUTE_ARRAY,
        this.selectedStartMinute
      );
    },

    endHourArray: function () {
      return this.composeDataArray(MONITOR_HOUR_ARRAY, this.selectedEndHour);
    },

    endMinuteArray: function () {
      return this.composeDataArray(
        MONITOR_MINUTE_ARRAY,
        this.selectedEndMinute
      );
    },
  },

  methods: {
    onStartHourClick: function (startHour) {
      this.$emit("onChangeMonitorDuration", {
        startHour: startHour,
        startMinute: this.selectedStartMinute,
        endHour: this.selectedEndHour,
        endMinute: this.selectedEndMinute,
      });
    },

    onStartMinuteClick: function (startMinute) {
      this.$emit("onChangeMonitorDuration", {
        startHour: this.selectedStartHour,
        startMinute: startMinute,
        endHour: this.selectedEndHour,
        endMinute: this.selectedEndMinute,
      });
    },

    onEndHourClick: function (endHour) {
      this.$emit("onChangeMonitorDuration", {
        startHour: this.selectedStartHour,
        startMinute: this.selectedStartMinute,
        endHour: endHour,
        endMinute: this.selectedEndMinute,
      });
    },

    onEndMinuteClick: function (endMinute) {
      this.$emit("onChangeMonitorDuration", {
        startHour: this.selectedStartHour,
        startMinute: this.selectedStartMinute,
        endHour: this.selectedEndHour,
        endMinute: endMinute,
      });
    },

    composeDataArray: function (rawDataArray, selectedItem) {
      const selectedItemIndex = rawDataArray.indexOf(selectedItem);
      const array1 = rawDataArray.slice(0, selectedItemIndex);
      const array2 = rawDataArray.slice(selectedItemIndex);
      const array3 = array2.concat(array1);
      return array3;
    },
  },
};
</script>

<style lang="scss" scoped>

.time-picker {
  color: rgba($color: black, $alpha: 0.65);
  font-size: rem(14);
  background-color: white;
  width: rem(250);
  box-shadow: rem(0) rem(3) rem(8) rem(0) rgba($color: black, $alpha: 0.15);
  border-radius: rem(4);

  &-top {
    border-bottom: rem(1) solid #e8e8e8;
    box-sizing: border-box;
    padding-left: rem(8);
    padding-right: rem(8);
    width: 100%;
    height: rem(30);
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-bottom {
    width: 100%;
    height: rem(129);
    display: flex;

    :last-child {
      border-right: none;
    }

    &-column {
      border-right: rem(1) solid rgba($color: black, $alpha: 0.09);
      width: 25%;
      max-height: rem(129);
      overflow: hidden;
      overflow-y: auto;

      &-item {
        width: 100%;
        height: rem(32);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #f7f7f7;
        }

        &-selected {
          background-color: #e6f7ff;

          &:hover {
            background-color: #e6f7ff;
          }
        }
      }
    }
  }
}

//动画
.fold-enter-active {
  animation-duration: $animation-duration;
  animation-name: fold-height-enter;
}
@keyframes fold-height-enter {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: rem(159);
    opacity: 1;
  }
}
.fold-leave-active {
  animation-duration: $animation-duration;
  animation-name: fold-height-leave;
}
@keyframes fold-height-leave {
  0% {
    max-height: rem(159);
    opacity: 1;
  }

  100% {
    max-height: 0;
    opacity: 0;
  }
}
</style>